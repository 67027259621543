import { Carousel } from 'antd';
import styled from 'styled-components';

export const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    width: 35px;
    height: 4px;
    border-radius: 5px;
    background: #222222;
  }
  > .slick-dots li.slick-active button {
    width: 65px;
    height: 4px;
    border-radius: 5px;
    background: #37944a;
  }

  > .slick-dots ul{
    display: flex !important;
    gap: 50px;
  }

  > .slick-dots li {
    padding: 0px 15px 0 15px;
    margin: 0;
  }
  > .slick-dots li.slick-active {
    padding: 0px 35px 0 15px;
    margin: 0;
  }
  > .slick-dots{

  }
  > .slick-dots-bottom {
    top: 470px;
    right: 50px;

    @media (max-width: 940px) {
      top: 420px;
    }


    @media (max-width: 640px) {
      margin: auto;
    }
  }
  > .slick-list {
    width: 100%;
    box-shadow: none;
    overflow: hidden;
  }

  left: 78px;
  width: 90%;
  height: 600px;
  margin-top: 32px;
  overflow: hidden;

  @media (max-width: 940px) {
    left: 10px;
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.115em;
  margin: 0;
  color: #37944a;

  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 18px;
  }
`;
export const Title2 = styled.h2`
  width: 683px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 500;
  font-size: 40.3969px;
  line-height: 99%;
  text-align: center;
  letter-spacing: 0.08em;
  margin: 0;
  color: #272727;

  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 640px) {
    height: 25px;
  }
`;

export const CarouselContainer = styled.div`
  width: 90%;
  height: 600px;
  gap: 30px;
  @media (max-width: 940px) {
    width: 400px;
  }
`;
export const ContainerCases = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 80px;
`;
