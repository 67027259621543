import styled from 'styled-components';

interface TextProps {
    top?: string;
}

export const FadingContainer = styled.div<TextProps>`
    margin-top: ${(props) => props.top};
`;

export const DecorativeText = styled.h4`
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    color: #31974A;
    @media only screen and (max-width: 1000px) {
        display: none;
    }
`;

export const FRow = styled.h4`
    opacity: 0.7;
    margin: 0px;
`;

export const SRow = styled.h4`
    opacity: 0.4;
    margin: 0px;
`;

export const TRow = styled.h4`
    opacity: 0.2;
    margin: 0px;
`;
