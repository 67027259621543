import React from 'react';
import { PsPhoto } from '../../assets';
import {
  BtnForms,
  SectionSub,
  Title,
  LeftSection,
  RightSection,
  Description,
  CenterSection,
  ImageSub,
  EnterLink,
} from './style';

export const Subscribe: React.FC = () => (
  <SectionSub id="contact">
    <LeftSection>
      <Title>CURTIU? FAÇA PARTE DO NOSSO TIME.</Title>
      <EnterLink href="https://forms.gle/tr4zNvTt2e2iHFFA9" target="_blank">
        <BtnForms />
      </EnterLink>
    </LeftSection>
    <CenterSection>
      <ImageSub src={PsPhoto} />
    </CenterSection>
    <RightSection>
      <Description>
        Realizamos processos seletivos todos os semestres buscando pessoas que vivem nossos valores.
      </Description>
    </RightSection>
  </SectionSub>
);
