import React from 'react';
import {
  DataContainer, DataTitle, DataNumber, DataRows, Box, Text, SparkleImageLeft, SparkleImageRight,
} from './style';
import { Sparkles } from '../../assets';

export const Data: React.FC = () => (
  <DataContainer>
    <DataTitle>NOS ÚLTIMOS 5 ANOS...</DataTitle>
    <DataRows>
      <Box>
        <DataNumber>100+</DataNumber>
        <Text>Empresas Humanizadas</Text>
      </Box>
      <Box>
        <DataNumber>300+</DataNumber>
        <Text>Pessoas Transformadas</Text>
      </Box>
      <SparkleImageLeft src={Sparkles} alt="Luzes decorativas" />
      <SparkleImageRight src={Sparkles} alt="Luzes decorativas" />
    </DataRows>
  </DataContainer>
);
