import React, { FunctionComponent } from 'react';
import {
  DecorativeText, FRow, SRow, TRow, FadingContainer,
} from './style';

export interface Props {
  word?: string | FunctionComponent;
  topM?: string;
}

export const FadingWords: FunctionComponent<Props> = ({ word, topM }: Props) => (
  <FadingContainer top={topM}>
    <DecorativeText>
      <FRow>{word}</FRow>
      <br />
      <SRow>{word}</SRow>
      <br />
      <TRow>{word}</TRow>
    </DecorativeText>
  </FadingContainer>
);

FadingWords.defaultProps = { word: '', topM: '' };
