import styled from 'styled-components';

export const Content = styled.div`
  width: 90%;
  @media only screen and (max-width: 1000px) {
    width: 95%;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 420px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center;

  border-radius: 11px;

  margin-right: 10px;

  @media (max-width: 640px) {
    width: 100%;
    height: 185px;
  }
`;

export const Image = styled.img`
  width: auto;
  height: 420px;
  border-right: solid #419F4B 24px;
  border-radius: 11px;

  @media (max-width: 640px) {
    width: auto;
    height: 100%;
    border-right: solid #419F4B 12px;
  }
`;
