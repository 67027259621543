import React from 'react';
import {
  Content,
  Card,
  PrincipalImage,
  Name,
  Text,
  Service,
  BoxText,
  ImageContainer,
} from './styles';

interface CardCasesProps {
  name: string;
  text: string;
  service: string;
  boxText: string;
  image: string;
}

export const CardCases = ({
  name,
  text,
  service,
  boxText,
  image,
}: CardCasesProps) => (
  <Content>
    <Card>
      <ImageContainer>
        <PrincipalImage src={image} alt="Foto Carrossel" />
      </ImageContainer>
      <Name>{name}</Name>
      <Text>{text}</Text>
      <Service>{service}</Service>
      <BoxText>{boxText}</BoxText>
    </Card>
  </Content>
);
