import React from 'react';
import {
  BackGroundColor,
  Container,
  Home2Image,
  Home2Vector,
  BoxTitles,
  Title,
  SubTitle,
  BoxImg,
} from './style';

import {
  Home2Photo,
  Home2Gradient,
} from '../../assets';

export const Home2 = () => (
  <BackGroundColor>
    <Container>
      <BoxTitles>
        <Title>PAIXÃO POR HUMANIZAR</Title>
        <SubTitle>Há 22 anos impactando ecossistemas através da Gestão de pessoas.</SubTitle>
      </BoxTitles>
      <BoxImg>
        <Home2Image src={Home2Photo} alt="Time Ethos" />
        <Home2Vector src={Home2Gradient} />
      </BoxImg>
    </Container>
  </BackGroundColor>
);
