import { Navbar } from '../Navbar';
import { Footer } from '../Footer';
import { PageTwoContainer } from './style';
import { Home2 } from '../Home2';
import { Essence } from '../Essence';
import { Multiverse } from '../Multiverse';
import { Process } from '../Process';
import { Subscribe } from '../Subscribe';
import { CarouselWayEthos } from '../WayEthos';

export const PageTwo = () => (
  <PageTwoContainer>
    <Navbar />
    <Home2 />
    <Essence />
    <Multiverse />
    <CarouselWayEthos />
    <Process />
    <Subscribe />
    <Footer />
  </PageTwoContainer>
);
