import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  NavbarContainer, Logo, EmptyDiv, HumburguerMenu,
  HumburguerLine1, HumburguerLine2, HumburguerLine3,
  MenuContainer, MenuItem, Links, InnerMenuContainer,
} from './style';

import { LogoSecundaria } from '../../assets';

export const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((current: boolean) => !current);
  };

  return (
    <NavbarContainer>
      <Logo src={LogoSecundaria} alt="Logo da empresa" />
      <HumburguerMenu className={isActive ? 'active' : ''} onClick={handleClick}>
        <HumburguerLine1 />
        <HumburguerLine2 />
        <HumburguerLine3 />
      </HumburguerMenu>
      <EmptyDiv />
      <MenuContainer className={isActive ? 'active' : ''}>
        <InnerMenuContainer>
          <MenuItem className={isActive ? 'active' : ''}>
            <Links>
              <NavLink to="/" exact>O que fazemos</NavLink>
            </Links>
          </MenuItem>
          <MenuItem className={isActive ? 'active' : ''}>
            <Links>
              <NavLink to="/QuemSomos" exact>Quem somos</NavLink>
            </Links>
          </MenuItem>
          <MenuItem className={isActive ? 'active' : ''}>
            <Links href="#contact">
              Contato
            </Links>
          </MenuItem>
        </InnerMenuContainer>
      </MenuContainer>
    </NavbarContainer>
  );
};
