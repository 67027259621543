import React from 'react';
import {
  CarouselWrapper, ContainerWayEthos,
  TitleContainer, Title, SubTitle,
  CarouselContainer,
} from './style';
import { CardWayEthos } from './CardWayEthos';
import {
  Ethos1, Ethos2, Ethos3, Ethos4, Ethos5, Ethos6, WayEthos,
} from '../../assets';

export const CarouselWayEthos = () => {
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    centerPadding: '50px',
    speed: 700,
    slidesToShow: 1.2,
    slidesToScroll: 0.7,
    centerMode: true,
  };

  if (window.innerWidth < 640) {
    settings.slidesToShow = 1;
    settings.slidesToScroll = 1;
    settings.centerPadding = '0px';
  }

  const content = [
    {
      id: 1,
      image: Ethos1,
    },
    {
      id: 2,
      image: Ethos2,
    },
    {
      id: 3,
      image: Ethos3,
    },
    {
      id: 4,
      image: Ethos4,
    },
    {
      id: 5,
      image: Ethos5,
    },
    {
      id: 6,
      image: Ethos6,
    },
  ];

  return (
    <ContainerWayEthos>
      <TitleContainer>
        <Title>O JEITO ETHOS</Title>
        <SubTitle>Quem somos?</SubTitle>
      </TitleContainer>
      <CarouselContainer>
        <CarouselWrapper {...settings}>
          {content.map((item) => (
            <CardWayEthos id={item.id} image={item.image} />
          ))}
        </CarouselWrapper>
      </CarouselContainer>
    </ContainerWayEthos>
  );
};
