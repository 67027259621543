import React from 'react';
import {
  HomePhase, NormalPhase,
  GreenPhase, FirstVector, SecondVector, PrincipalImage,
  GreenBackground, BackGroundColor,
  ButtonContact,
} from './style';
import {
  Rectangle,
  Vector8,
  Vector9,
  HomePhoto,
} from '../../assets';

export const HomePage: React.FC = () => (

  <BackGroundColor>
    <HomePhase>
      <NormalPhase>DES(envolver) </NormalPhase>
      <GreenPhase>pessoas </GreenPhase>
      <NormalPhase>e </NormalPhase>
      <GreenPhase>humanizar </GreenPhase>
      <NormalPhase>ambientes </NormalPhase>
    </HomePhase>
    <FirstVector src={Vector9} alt="Imagem de uma estrela com várias pontas." />
    <SecondVector src={Vector8} alt="Imagem de uma estrela com várias pontas." />
    <PrincipalImage src={HomePhoto} alt="Duas pessoas abraçadas." />
    <GreenBackground src={Rectangle} alt="Fundo esverdeado" />
    <a href="#contact">
      <ButtonContact />
    </a>
  </BackGroundColor>
);
