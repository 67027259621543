/* eslint-disable max-len */
import React from 'react';
import {
  DataContainer,
  EnterButton,
  EnterLink,
  Galaxy,
  LogoContainer,
  LogoImages,
  MultiContainer,
  SectionTitle,
  SectionText,
  VoicesImage,
  SparklesMultiverse,
} from './style';
import {
  AcasoGalaxy, AcasoSVG,
  EthosLogo, Voices, SparklesWayEthos,
} from '../../assets';

export const Multiverse: React.FC = () => (
  <MultiContainer>
    <Galaxy src={AcasoGalaxy} />
    <DataContainer>
      <LogoContainer>
        <LogoImages src={EthosLogo} />
        <LogoImages src={AcasoSVG} />
      </LogoContainer>
      <SectionTitle>MULTIVERSO</SectionTitle>
      <VoicesImage src={Voices} />
      <SectionText>
        Entendimento do contexto do RH da empresa e criação de estratégias para a área de Pessoas utilizando People Analytics. Entendimento do contexto do RH da empresa e criação de estratégias para a área de Pessoas utilizando People Analytics. Entendimento do contexto do RH da empresa.
      </SectionText>
      <EnterLink href="http://bit.ly/multiversovozes" target="_blank">
        <EnterButton>
          ENTRAR NO MULTIVERSO
        </EnterButton>
      </EnterLink>
      <SparklesMultiverse src={SparklesWayEthos} />
    </DataContainer>
  </MultiContainer>
);

/*
  border radius
  small screens
*/
