import { Navbar } from '../Navbar';
import { Data } from '../Data';
import { HomePage } from '../HomePage';
import { Services } from '../Services';
import { Carrossel } from '../Cases';
import { CallToAction } from '../CallToAction';
import { PageOneContainer } from './style';
import { Ecosystem } from '../Eco';
import { Footer } from '../Footer';

export const PageOne = () => (
  <PageOneContainer>
    <Navbar />
    <HomePage />
    <Services />
    <Data />
    <Carrossel />
    <Ecosystem />
    <CallToAction />
    <Footer />
  </PageOneContainer>
);
