import styled from 'styled-components';
import { Carousel } from 'antd';
import { WavesWayEthos } from '../../assets';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5%;
  position: relative;
  bottom: 150px;
  @media only screen and (max-width: 1000px){
    bottom: 50px;
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  color: #37944A;
  @media only screen and (max-width: 1000px){
    font-size: 20px;
  }

  @media (max-width: 640px){
    font-size: 16px;
  }
`;

export const SubTitle = styled.h2`
  font-size: 40.4px;
  color: #272727;
  @media only screen and (max-width: 1000px){
    font-size: 36.4px;
  }

  @media (max-width: 640px){
    font-size: 20px;
  }
`;

export const ContainerWayEthos = styled.div`
  width: 100%;
  height: 960px;
  display: flex;
  flex-direction: column;
  padding-top: 20%;
  padding-bottom: 50%;
  background-image: url(${WavesWayEthos});
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  @media only screen and (max-width: 900px) {
    height: 800px;
  }
  @media only screen and (max-width: 750px) {
    height: 500px;
  }
  background-color: #fafafa;
`;

export const CarouselContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  bottom: 30px;
  @media only screen and (max-width: 1000px){
    bottom: -20px;
  }
`;

export const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    width: 155%;
    height: 4px;
    border-radius: 5px;
    background: #222222;
  }
  > .slick-dots li.slick-active button {
    width: 200%;
    height: 4px;
    border-radius: 5px;
    background: #48A14C;
  }
  > .slick-dots li {
    padding: 0px 10px 0 15px;
    margin: 0;
  }
  > .slick-dots li.slick-active {
    padding: 0px 25px 0 15px;
    margin: 0;
  }
  > .slick-dots-bottom {
    bottom: -20%;
    right: 30%;
  }
  > .slick-list {
    padding: 0 17% !important;
  }
  left: 15%;
  width: 100%;
  height: 60%;

  @media (max-width: 640px) {
    left: 5%;
    
    > .slick-list {
      padding: 0 0 !important;
    }

    > .slick-dots-bottom {
    bottom: -20%;
    right: 15%;
  }
  }
`;
