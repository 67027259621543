import React from 'react';
import {
  CardContainer, CardTag, CardTitle,
  CardTitleText, CardContent, CardContentText,
} from './style';

interface CardProps {
  title: string;
  text: string;
  tag: string;
}

export const CardServices = ({ tag, title, text }: CardProps) => {
  const teste = 'teste';
  return (
    <CardContainer>
      <CardTag>
        {tag}
      </CardTag>
      <CardTitle>
        <CardTitleText>{title}</CardTitleText>
      </CardTitle>
      <CardContent>
        <CardContentText>
          {text}
        </CardContentText>
      </CardContent>
    </CardContainer>
  );
};
