import { createGlobalStyle } from 'styled-components';
import '@fontsource/comfortaa';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Comfortaa';
  }

  html {
    width: 100%;
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width:720px) {
      font-size: 87.5%;
    }

    scroll-behavior: smooth;
  }

  body {
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    font: 400 16px 'comfortaa', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-family: 'comfortaa', sans-serif;
    overflow-x: hidden;
  }

  /* .ant-btn-default:hover{
    background-color: #49A24C;
     color: white;
     border: 1px solid #49A24C;
} */

  //   @media (max-width:720px) {
  //     font-size: 87.5%;
  //   }
  // }

  // // body {
  // //   background: ${(props) => props.theme.colors.background};
  // //   color: ${(props) => props.theme.colors.text};
  // //   font: 400 16px Roboto, sans-serif;
  // //   -webkit-font-smoothing: antialiased;
  // // }

 /* body {
   margin: 0;
   overflow-x: hidden;
 } */

  // button {
  //   cursor: pointer;
  // }

  // [disabled] {
  //   opacity: 0.6;
  //   cursor: not-allowed;
  // }
`;
