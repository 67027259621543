import styled from 'styled-components';

export const EcoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const EcoStripes = styled.img`
  width: 100%;
  height: 70%;
  position: relative;
  top: 220px;
  @media only screen and (max-width: 1300px) {
    height: 50%;
    position: relative;
    top: 700px;
  }
  @media only screen and (max-width: 900px) {
    height: 40%;
    position: relative;
    top: 700px;
  }
  @media only screen and (max-width: 750px) {
    top: ;
  }
  @media only screen and (max-width: 640px) {
    top: 600px;
  }
  @media only screen and (max-width: 500px) {
    top: 500px;
  }
  @media only screen and (max-width: 400px) {
    top: 300px;
  }
`;

export const EcoBox = styled.div`
  width: 500px;
  height: 500px;
  margin: 0 auto;
  display: flex;
  margin-top: -15%;
  align-items: center;
  flex-flow: column nowrap;
  z-index: 1;
  @media only screen and (max-width: 1300px) {
    margin-top: -200px;
    height: auto;
  }
  @media only screen and (max-width: 750px) {
    width: 300px;
  }
  @media only screen and (max-width: 550px) {
    width: 250px;
  }
`;

export const EcoTitle = styled.h2`
  font-family: Comfortaa;
  font-weight: 500;
  color: #0c0c0c;
  align-self: center;
  font-size: 30px;
  margin-top: 24px;
  @media only screen and (max-width: 750px) {
    font-size: 150%;
  }
  @media only screen and (max-width: 500px) {
    font-size: 120%;
  }
`;

export const EcoSubtitle = styled.h3`
  font-family: Comfortaa;
  font-weight: 700;
  color: #0c0c0c;
  align-self: center;
  font-size: 45px;
  text-align: center;
  margin-top: 20px;
  @media only screen and (max-width: 750px) {
    font-size: 250%;
  }
  @media only screen and (max-width: 500px) {
    font-size: 150%;
  }
`;

export const SparkleImageLeft = styled.img`
  height: 20%;
  width: 180px;
  position: relative;
  transform: rotate(-180deg);
  top: 1%;
  left: 4%;
  @media only screen and (max-width: 900px) {
    top: 2%;
    left: 10%;
    width: 20%;
    height: 13%;
  }
  @media only screen and (max-width: 750px) {
    left: 10%;
    width: 15%;
    height: 10%;
  }
  @media only screen and (max-width: 400px) {
    top: -100px;
    width: 10%;
  }
`;

export const SparkleImageRight = styled.img`
  height: 150px;
  width: 120px;
  position: relative;
  top: 8%;
  left: 88%;

  @media only screen and (max-width: 1300px) {
    top: 100px;
    left: 85%;
  }
  @media only screen and (max-width: 900px) {
    top: 6%;
  }
  @media only screen and (max-width: 750px) {
    top: 3%;
    left: 75%;
    height: 7%;
    width: 15%;
  }
  @media only screen and (max-width: 400px) {
    top: -50px;
    left: 85%;
    width: 10%;
    height: 5%;
  }
`;

export const FadingContainerTop = styled.div`
  margin: 0 auto;
  width: 50%;
  height: 150px;
  position: absolute;
  margin-top: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  justify-self: center;
  @media only screen and (max-width: 1300px) {
    margin-top: 55%;
  }
  @media only screen and (max-width: 1100px) {
    margin-top: 65%;
  }
`;

export const FadingContainerBottom = styled.div`
  margin: 0 auto;
  width: 80%;
  height: 150px;
  position: absolute;
  margin-top: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  justify-self: center;
  @media only screen and (max-width: 1300px) {
    margin-top: 75%;
  }
  @media only screen and (max-width: 1100px) {
    margin-top: 85%;
  }
`;

export const WordHolder = styled.div`
  margin: auto;
  margin-top: -900px;
  height: 300px;
  display: flex;
  flex-direction: column;
  /* @media only screen and (max-width: 1300px){
    margin-top: -1050px;
  } */
`;
