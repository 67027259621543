import styled from 'styled-components';
import { Button } from 'antd';

export const Container = styled.div`
`;

export const FirstPhrase = styled.h1`
    padding-top: 2rem;
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.115em;
    color: #37944A;
    margin-bottom: 24px;

    @media (max-width: 640px) {
        font-size: 16px;
    }
`;

export const SecondPhrase = styled.h1`
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 99%;
    text-align: center;
    letter-spacing: 0.08em;
    color: #272727;
    margin-bottom: 40px;

    @media (max-width: 640px) {
        font-size: 25px;
    }
`;

export const ThirdPhrase = styled.h1`
    width: 80vw;
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.135em;
    color: #424242;
    margin: auto;
    text-align: center;
    margin-bottom: 80px;

    @media (max-width: 640px) {
        font-size: 12px;
    }
`;

export const SectionOne = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 86px;
    width: 410px;
    height: 227px;
`;

export const SectionTwo = styled.div`

    display:flex;
    width: 379px;
    height: 750px;
    flex-direction: column;
    column-gap: 86px;
`;

export const Box = styled.div`
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-top: 4vw;
    flex-wrap: wrap;
    column-gap: 10%;
    align-items: baseline;
`;

export const BoxTwo = styled.div`
    display:flex;
    flex-direction: row;
    column-gap: 30px;
    position: relative;
    padding-bottom: 4%;
    align-items: flex-start;
    @media only screen and (max-width: 640px) {
        column-gap: 35px;
    }
`;

export const BoxThree = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 640px) {
        
    }
`;

export const Circle = styled.div`
    width: 54px;
    height: 54px;
    background: #49A24C;
    border-radius: 50%;
    @media only screen and (max-width: 640px) {
        width: 34px;
        height: 34px;
    }
`;

export const Icon = styled.h1`
    text-align: center;
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.135em;
    justify-content: center;
    position: relative;
    top: 40px;
`;

export const TextBox = styled.div`
    width: 425px;
    height: 80%;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 640px) {
        width: 70%;
        position: relative;
        /* left: -5%; */
        top: -10px;
    }
`;

export const TextOne = styled.h1`
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.135em;
    color: #424242;
    margin-top: 15px;
    @media (max-width: 640px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 640px){
        width: 250px;
        /* font-size: 12px; */
    }
`;

export const TextTwo = styled.h1`
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.135em;
    color: #424242;
    margin-top: 10px;

    @media (max-width: 640px) {
        font-size: 12px;
    }
    @media only screen and (max-width: 640px) {
        width: 220px;
    }
`;

export const ButtonStyled = styled(Button)`
    width: 70%;
    height: 30%;
    font-weight: 500;
    font-size: 14px;
    background-color: #fafafa;
    color: #49A24C;
    border: 1px solid #49A24C;
    margin-top: 8%;
    &.ant-btn-default:hover{
        color: #424242;
        border: 1px solid #424242;
    }
`;

export const ButtonClose = styled(Button)`
    background: #424242;
    box-shadow: none;
    color: #fff;
    font-size: 12px;
    &.ant-btn-default:hover{
        color: #fff;
        border: 1px solid transparent;
    }
`;

export const ButtonNext = styled(Button)`
    width: 84px;
    height: 84px;
    position: absolute;
    right: 1%;
    margin-top: -250px;
    background: none;
    color: #49A24C;
    font-size: 40px;
    border: 1px solid transparent;
    &.ant-btn-link:hover{
        color: #424242;
    }
    @media only screen and (max-width: 500px) {
        position: relative;
        top: 30px;
        left: 30%;
    }
`;
export const ButtonBack = styled(Button)`
    width: 84px;
    height: 84px;
    position: absolute;
    left: 1%;
    margin-top: -250px;
    background: none;
    color: #49A24C;
    font-size: 40px;
    border: 1px solid transparent;
    &.ant-btn-link:hover{
        color: #424242;
    }
    @media only screen and (max-width: 500px) {
        position: relative;
        top: 30px;
        left: 30%;
    }
`;

export const DetailsContainer = styled.div`
    display: flex;
    margin-top: 10px;
`;

export const ExpandContainer = styled.div`
`;
