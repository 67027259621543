import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 461px;
  margin-left: 50px;
  //padding: 0 0 0 50px;
  //left: 140px;
  color: black;
  text-align: center;

  @media (max-width: 1500px) {
    margin: 0 0 0 0;
  }

  @media (max-width: 940px) {
    padding: 0 0 0 10px;
  }

  @media (max-width: 640px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
export const Card = styled.div`
  position: relative;
  width: 1033px;
  height: 392px;
  left: 0px;
  top: 23px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.14);
  border-radius: 26px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 940px) {
    width: 500px;
    box-shadow: none;
    background-color: #fafafa;
  }

  @media (max-width: 640px) {
    width: 270px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-right: 50px;

    border-radius: 0;
  }

  @media (max-width: 400px) {
    width: 200px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 0;
  }
`;

export const ImageContainer = styled.div`
  width: 390px;

  position: relative;
  left: 0px;

  border-radius: 26px 0 0 26px;
  overflow: hidden;

  @media (max-width: 940px) {
    width: 370px
  }

  @media (max-width: 640px) {
    width: 275px;
    border-radius: 0;
  }

  @media (max-width: 400px) {
    width: 200px;
    border-radius: 0;
  }
`;

export const PrincipalImage = styled.img`
  width: 100%;
  height: auto;
`;
export const Name = styled.div`
  position: absolute;
  top: 44px;
  left: 434px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.115em;
  color: #37944a;
  
  @media (max-width: 640px) {
    display: none;
  }
`;
export const Text = styled.div`
  position: absolute;
  width: 568px;
  height: 130px;
  text-align: initial;
  top: 108px;
  right: 31px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  line-height: 130.5%;
  letter-spacing: 0.08em;
  color: #272727;

  @media (max-width: 940px) {
    display: none;
  }
`;
export const Service = styled.div`
  position: absolute;
  top: 277px;
  left: 434px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.115em;
  color: #9c9c9c;

  @media (max-width: 940px) {
    display: none;
  }
`;
export const BoxText = styled.div`
  width: 250px;
  height: 50px;

  position: absolute;
  top: 309px;
  left: 434px;

  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items:center;

  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.115em;
  color: #272727;
  border: 1px solid #272727;
  border-radius: 4px;

  @media (max-width: 940px) {
    display: none;
  }
`;

export const Title = styled.h1`
  width: 648px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.115em;
  margin: 0;
  color: #37944a;

  @media (max-width: 940px) {
    display: none;
  }
`;
export const Title2 = styled.h2`
  width: 683px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 500;
  font-size: 40.3969px;
  line-height: 99%;
  text-align: center;
  letter-spacing: 0.08em;
  margin: 0;
  color: #272727;

  @media (max-width: 940px) {
    display: none;
  }
`;
export const TextContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 940px) {
    display: none;
  }
`;
