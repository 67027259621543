import styled from 'styled-components';

export const ContainerFooter = styled.div`
  width: 100%;
  /* height: 327px; */
  background-color: #303030;
  bottom: 0;
  position: relative;
  top: 300px;
  h1 {
    font-size: 24px;
    color: ${(props) => props.theme.colors.primary};
    margin-top: 40px;
    @media (max-width: 720px) {
      font-size: 28px;
    }
  }

  p {
    margin-top: 0;
    line-height: 20px;
    font-size: 16px;
    @media (max-width: 720px) {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 1000px) {
    top: 600px;
  }
  @media only screen and (max-width: 640px) {
    top: 550px;
  }
  @media only screen and (max-width: 400px) {
    top: 250px;
  }
`;

export const ContainerRow = styled.div`
  z-index: 2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  align-self: baseline;

  @media (max-width: 1266px) {
    flex-direction: column;
    height: auto;
    padding: 30px;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 1000px) {
    /* top: 600px; */
  }
  @media only screen and (max-width: 400px) {
    /* top: 250px; */
  }
`;
export const ContainerDivision = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  gap: 24px;
  flex-direction: column;
  padding: 0 60px;
`;

export const FooterSectionHeader = styled.h1`
  width: 100%;
  font-weight: bold;
  color: #ffffff;
  text-align: right;

  @media (max-width: 1266px) {
    text-align: center;
  }
  @media (max-width: 640px){
    display: none;
  }
`;

export const FooterSectionText = styled.p`
  font-size: 12px;
  width: 100%;
  text-align: right;

  @media (max-width: 1266px) {
    text-align: center;
  }
  @media (max-width: 640px){
    display: none;
  }
`;
export const FooterSectionTextLocal = styled.p`
  font-size: 12px;
  width: 100%;
  text-align: right;

  @media (max-width: 1266px) {
    text-align: center;
  }
  @media (max-width: 640px){
    display: none;
  }
`;

export const FooterMainLogo = styled.img`
  width: 300px !important;
  height: 100px;
  @media (max-width: 640px){
    width: 80% !important;
  }
`;

export const FooterSocialMedia = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  gap: 32px;

  & img {
    width: 32px;
    height: 32px;
  }

  @media (max-width: 1266px) {
    justify-content: center;
  }
`;

export const FooterSocialMediaLogo = styled.img`
  &:hover {
    cursor: pointer;
  }
`;
export const FooterSocialMediaLogoEmail = styled.img`
  display: none;
  @media (max-width: 640px){
    display: block;
  }
`;

export const FooterSectionLinks = styled.a`
`;

export const Line = styled.hr`
  margin-top: 3%;
  border: 0.5px groove #494949;
`;

export const CopyRight = styled.div`
  p{
    word-spacing: 2px;
  }
  img{
    margin: 0 6px 0 6px;
  }
  @media only screen and (max-width: 640px) {
    p {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 400px) {
    p {
      font-size: 10px;
    }
  }
`;

export const ContainerCopy = styled.div`
  margin-top: 1%;
  margin-bottom: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media only screen and (max-width: 600px){
    flex-direction: column;
    align-items: center;
  }
`;
