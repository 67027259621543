import { Button } from 'antd';
import styled from 'styled-components';

export const BackGroundColor = styled.div`
    height: 1100px;
    overflow: hidden;
    
    @media (max-width: 640px) {
        height: 700px;
        
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        
        position: relative;
        top: -90px;

        a {
            text-decoration: none;
            width: 230px;
            height: 50px;
        }
    };
   
    a {
        text-decoration: none;
    }
`;

export const HomePhase = styled.div`
    position: relative;
    height: 96px;
    width: 652px;
    left: calc(100% - 735px);
    margin-right: 0;
    top: 254px;
    line-height: 47.52px;
    text-align: inherit;
    z-index: 5;

    @media (max-width: 1250px) {
        width:500px;
        left:  calc(100% - 500px);
    }

    @media (max-width: 950px) {
        top: 150px;
    }

    @media (max-width: 640px) {
        width: 80%;
        left: 0;
    };
`;

export const GreenPhase = styled.span`
    font-family: 'univa-pro', sans-serif;
    font-weight: 700;
    font-size: 48px;
    letter-spacing: 0.08em;
    background-image: linear-gradient(to bottom, #84B153, #419F4B);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    @media (max-width: 1250px) {
        font-size: 36px;
    }

    @media (max-width: 640px) {
        font-size: 25px;
    };

`;

export const NormalPhase = styled.span`
    font-family: 'Comfortaa', sans-serif;
    font-weight: 500;
    font-size: 48px;
    letter-spacing: 0.08em;
    color: black;
    @media (max-width: 1250px) {
        font-size: 36px;
    }

    @media (max-width: 640px) {
        font-size: 25px;
    };
`;

export const FirstVector = styled.img`
    position: absolute;
    width: 541.62px;
    height: 542.55px;
    left: 167px;
    top: 525.74px;
    transform: rotate(-11deg);
    z-index: 1;
    @media (max-width: 775px) {
        display: none;
    }
`;

export const SecondVector = styled.img`
    position: absolute;
    width: 541.62px;
    height: 542.55px;
    left: 171px;
    top: 573.74px;
    z-index: 2;
    transform: rotate(-8.57deg);
    @media (max-width: 775px) {
        display: none;
    }
`;

export const PrincipalImage = styled.img`
    position: absolute;
    width: 499.77px;
    height: auto;
    left: 0px;
    top: 196px;
    z-index: 3;

    @media (max-width: 640px) {
        display: none;
    };
`;

export const GreenBackground = styled.img`
    position: absolute;
    width: 1178px;
    left: 0px;
    height: 1178px;
    top: 124px;
    z-index: 0;
    @media (max-width: 1178px){
        width: 100%;
        overflow: hidden;
    };

    @media (max-width: 640px) {
        display: none;
    };
`;

export const ButtonContact = styled.button`
    padding: 20px 60px;
    border: none;
    outline: none;
    position: relative;
    border-radius: 5px;
    background: linear-gradient(to right, #84B153, #419F4B);
    cursor: pointer;
    width: 355px;
    height: 62px;
    top: 299px;
    left: calc(100% - 450px);

    @media (max-width: 1250px) {
        left: calc(100% - 385px);
    }

    @media (max-width: 950px) {
        top: 195px;
    }

    @media (max-width: 640px) {
        width: 230px;
        height: 50px;
        left: 0;
        top: -50px;

        padding: 0 0;
        
        &:after{
            content: "CONTATO";
            background: linear-gradient(to top, #84B153, #419F4B);
            -webkit-background-clip: text;
            color: transparent;
            transition: 200ms;
            text-decoration: none;
        }
    };

    @media (max-width: 400px) {
        top: -20px;
    };

    font-family: 'Comfortaa', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.29em;
    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15);
    z-index: 10;

    &:before {
        content: "";
        position: absolute;
        left: 3px;
        right: 3px;
        top: 3px;
        bottom: 3px;
        border-radius: 4px;
        background-color: #fafafa;
        z-index: -1;
    }

    &:after{
        content: "CONTATO";
        font-size: 16px;
        background: linear-gradient(to top, #84B153, #419F4B);
        -webkit-background-clip: text;
        color: transparent;
        transition: 200ms
    }

    &:hover::before {
        opacity: 0%;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    &:hover::after{
        color: #fafafa;
    }
`;
