import React from 'react';
import { Content, Card, Image } from './style';

interface CardWayEthosProps {
  id: number;
  image: string;
}

export const CardWayEthos = ({
  id,
  image,
}: CardWayEthosProps) => (
  <Content>
    <Card>
      <Image src={image} alt="Foto" />
    </Card>
  </Content>
);
