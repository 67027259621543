import styled from 'styled-components';

export const CallContainer = styled.div`
  width: 100%;
  height: 800px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  position: relative;
  top: 310px;
  margin: -400px 0 200px 0;

  gap: 100px;

  @media only screen and (min-width: 1000px){
    top: 600px;
    margin: 0px 0 200px 0;
    justify-content: space-between;
  }
  @media only screen and (min-width: 1300px){
    top: 600px;
    margin: -300px 0 200px 0;
    justify-content: space-between;
  }
  @media only screen and (max-width: 1050px){
    top: 600px;
    margin: 0px 0 200px 0;
    justify-content: center;
  }
  @media only screen and (max-width: 1000px) {
    top: 600px;
    margin: 200px 0 50px 0;
    justify-content: center;
  }
  @media only screen and (max-width: 800px) {
    top: 500px;
  }
  @media only screen and (max-width: 400px) {
    top: 200px;
  }
`;

export const CallImage = styled.img`
  width: 750px;
  height: auto;
  position: relative;
  right: 0px;
  top: 50px;
  z-index: -1;
  @media (max-width: 1400px){
    width: 600px;
    height: 700px;
    margin-top: 150px;
  }
  @media (max-width: 1320px) {
    width: 45%;
  }
  @media (max-width: 1200px) {
    width: 38%;
  }
  @media (max-width: 1050px) {
    display: none;
  }
`;

export const CallFormsContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  margin-left: 160px;
  height: 600px;
  width: 400px;
  position: relative;
  top: 50px;
  @media (max-width: 1050px) {
    margin: 0;
  }
`;

export const CallTitle = styled.h2`
  color: #272727;
  font-family: "Comfortaa", "Times New Roman", Times, serif;
  font-size: 28px;
  margin: auto;
  margin-top: 133px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;
  letter-spacing: 0.065em;

  @media (max-width: 400px) {
    font-size: 24px;
  }
`;

export const CallText = styled.h3`
  color: #000000;
  font-family: "Comfortaa", "Times New Roman", Times, serif;
  font-size: 42px;
  margin: auto;
  text-align: center;
  font-weight: 700;
  width: 380px;
  top: 60px;
  line-height: 40px;
  letter-spacing: 0.04rem;

  @media (max-width: 400px) {
    font-size: 36px;
  }
`;

const sliderThumbStyles = () => (`
  width: 13px;
  height: 13px;
  background: white;
  cursor: pointer;
  outline: 7px solid #009245;
  opacity: 1;
  border-radius: 50%;
  -webkit-transition: .2s;
  transition: opacity .2s;
`);

export const CallForm = styled.div`
  padding-bottom: 20px;

  .slider {
    flex: 6;
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(40,146,75,1) 100%);
    outline: none;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      ${sliderThumbStyles()}
    }
    &::-moz-range-thumb {
      ${sliderThumbStyles()}
    }
  }
`;

export interface SlideTextProps {
    value?: string;
  }

export const SlideText = styled.h6<SlideTextProps>`
  margin-left: ${(props) => props.value}px;
  text-align: left;
  width: auto;
  margin-top: 20px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150.5%;
  letter-spacing: -0.03em;
  color: #000000;
`;

export interface InputProps {
  height?: string;
}

export const CallInput = styled.input<InputProps>`
  border: 1.7px solid #27914A;
  width: 360px;
  height: ${(props) => (props.height)};
  align-self: center;
  justify-self: center;
  border-radius: 4px;
  background-color: #fafafa;
  font-family: "Comfortaa", "Times New Roman", Times, serif;
  padding: 8px;
  color: #000000;

  @media (max-width: 400px) {
    width: 300px
  }
`;

export const CallInputArea = styled.textarea<InputProps>`
  border: 1.7px solid #27914A;
  width: 360px;
  height: ${(props) => (props.height)};
  align-self: center;
  justify-self: center;
  border-radius: 4px;
  background-color: #fafafa;
  font-family: "Comfortaa", "Times New Roman", Times, serif;
  padding: 8px;
  resize: none;
  color: #000000;

  @media (max-width: 400px) {
    width: 300px
  }
`;

export const CallSlide = styled.input`
 && {
  display: flex;
  align-self: flex-start;
  justify-self: flex-start;
  margin-top: 1rem;
  width: 360px;

  @media (max-width: 400px) {
    width: 300px
  }
 }
`;

export const TopBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: -120px;
`;

export const FieldTitle = styled.h4`
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #7B7B7B;
  padding-bottom: 5px;
`;

export const CallButton = styled.button`
  width: 355px;
  height: 62px;

  position: relative;
  top: 20px;

  padding: 20px 60px;

  border: none;
  outline: none;
  border-radius: 5px;

  background: linear-gradient(to right, #84B153, #419F4B);
  cursor: pointer;

  font-family: 'Comfortaa', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.29em;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1;
  &:before {
      content: "";
      position: absolute;
      left: 3px;
      right: 3px;
      top: 3px;
      bottom: 3px;
      border-radius: 4px;
      background-color: #fafafa;
      z-index: -1;
  }

  &:after{
    content: "ENVIAR";
    font-size: 16px;
    background: linear-gradient(to top, #84B153, #419F4B);
    -webkit-background-clip: text;
    color: transparent;
    transition: 200ms
  }

  &:hover::before {
    opacity: 0%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  &:hover::after{
    color: #fafafa;
  }

  &.active:before {
    content: "";
    background: linear-gradient(to top, #84B153, #419F4B);
    -webkit-background-clip: text;
    color: transparent;
  }

  &.active:after {
    content: "";
    height: 62px;
    border-radius: 4px;
  }

  @media (max-width: 400px) {
    width: 300px;

    &:after{
      content: "ENVIAR";
      font-size: 14px;
      background: linear-gradient(to top, #84B153, #419F4B);
      -webkit-background-clip: text;
      color: transparent;
      transition: 200ms
  }
  }
`;

export const LoadingRing = styled.div`
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }

  :before {
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    border-radius: 10px;
    -webkit-box-shadow: 25px 0px 0 0 rgba(255, 255, 255, 0.2), 20.22542px 14.69463px 0 0 rgba(255, 255, 255, 0.4), 7.72542px 23.77641px 0 0 rgba(255, 255, 255, 0.6), -7.72542px 23.77641px 0 0 rgba(255, 255, 255, 0.8), -20.22542px 14.69463px 0 0 #fafafa;
            box-shadow: 25px 0px 0 0 rgba(255, 255, 255, 0.2), 20.22542px 14.69463px 0 0 rgba(255, 255, 255, 0.4), 7.72542px 23.77641px 0 0 rgba(255, 255, 255, 0.6), -7.72542px 23.77641px 0 0 rgba(255, 255, 255, 0.8), -20.22542px 14.69463px 0 0 #fafafa;
    height: 10px;
    width: 10px;
    display: block;
    content: '';
  }

  scale: 0.75;
`;

export const ContainerAlert = styled.div`
  width: 355px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-top: 30px;
`;

export const MessageAlert = styled.p`
  display: none;
  font-family: 'Comfortaa', sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.29em;
  color: #000;

  transition: 1s;

  &.active {
    display: flex;
    pointer-events: none;
  }
`;
