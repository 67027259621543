import React from 'react';
import {
  CopyRight, ContainerFooter, ContainerDivision, ContainerRow, ContainerCopy,
  FooterSectionHeader, FooterSectionText, FooterMainLogo,
  FooterSocialMediaLogo, FooterSectionLinks, FooterSocialMedia,
  FooterSectionTextLocal, FooterSocialMediaLogoEmail, Line,
} from './style';
import {
  CITiFooter,
  Heart,
  LogotipoSecundario,
  LogoFb,
  LogoInsta,
  LogoLinkedin,
  Email,
} from '../../assets';

export const Footer: React.ElementType = () => (
  <ContainerFooter>
    <ContainerRow>
      <ContainerDivision>
        <FooterMainLogo src={LogotipoSecundario} />
      </ContainerDivision>
      <ContainerDivision>
        <FooterSectionHeader>
          CONTATE A ETHOS
        </FooterSectionHeader>
        <FooterSectionText>
          executivo@ethosconsultoriajr.com
        </FooterSectionText>
        <FooterSocialMedia>
          <FooterSectionLinks href="https://www.facebook.com/EthosConsultoriaJr/" target="_blank">
            <FooterSocialMediaLogo src={LogoFb} />
          </FooterSectionLinks>
          <FooterSectionLinks href="https://www.instagram.com/ethosconsultoriajr/" target="_blank">
            <FooterSocialMediaLogo src={LogoInsta} />
          </FooterSectionLinks>
          <FooterSectionLinks href="https://www.linkedin.com/company/ethosconsultoria-jr/?originalSubdomain=br" target="_blank">
            <FooterSocialMediaLogo src={LogoLinkedin} />
          </FooterSectionLinks>
          <FooterSectionLinks href="mailto:comercial@ethosconsultoriajr.com" target="_blank">
            <FooterSocialMediaLogoEmail src={Email} />
          </FooterSectionLinks>
        </FooterSocialMedia>
      </ContainerDivision>
      <ContainerDivision>
        <FooterSectionHeader>
          ONDE NOS ENCONTRAR
        </FooterSectionHeader>
        <FooterSectionTextLocal>
          Av. Acadêmico Hélio Ramos | Centro de Filosofia e Ciências Humanas (CFCH) / UFPE,
          9° andar - sala 16, Recife, Pernambuco 50740530, BR
        </FooterSectionTextLocal>
      </ContainerDivision>
    </ContainerRow>
    <Line />
    <ContainerCopy>
      <CopyRight>
        <p> © Copyright 2023 Ethos | Todos os direitos reservados </p>
      </CopyRight>
      <CopyRight>
        <p>
          Made with &lt; / &gt; and
          <img src={Heart} alt="heart icon" />
          by
          <img src={CITiFooter} alt="Citi icon" />
        </p>
      </CopyRight>
    </ContainerCopy>
  </ContainerFooter>
);
