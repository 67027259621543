import React from 'react';
import { Circulo, WaveEssence } from '../../assets';
import {
  PhaseOne, PhaseTwo, PhaseContainer, Circle, BoxContainer, Box,
  TextOne, TextTwo, TextThree, TextTwoEdited, LeftContainer, Container,
  RightContainer,
  BgContainer,
  BgImage,
} from './style';

export const Essence: React.FC = () => (
  <BgContainer>
    <BgImage src={WaveEssence} />
    <Container>
      <LeftContainer>
        <Circle src={Circulo} alt="Foto de uma pessoa com a bandeira da Ethos" />
      </LeftContainer>
      <RightContainer>
        <PhaseContainer>
          <PhaseOne>NOSSA ESSÊNCIA</PhaseOne>
          <PhaseTwo>Valores da Ethos</PhaseTwo>
        </PhaseContainer>
        <BoxContainer>
          <Box>
            <TextOne>
              CONSTRUIR LEGADO
            </TextOne>
            <TextTwo>Qual história eu vou contar?</TextTwo>
            <TextThree>Pilares: Aprender&Ensinar,  Resultado&Impacto, Sonhos&Realidade.</TextThree>
          </Box>
          <Box>
            <TextOne>CORAGEM DE SER DONE</TextOne>
            <TextTwo>Vai com medo mesmo!</TextTwo>
            <TextThree>Pilares: Paixão,  Coragem,  Liderança Emergente.</TextThree>
          </Box>
          <Box>
            <TextOne>HUMANIZAR</TextOne>
            <TextTwoEdited>É sobre o olhar para as relações!</TextTwoEdited>
            <TextThree>Pilares: Humanidade,  Respeito&Justiças, Relações.</TextThree>
          </Box>
          <Box>
            <TextOne>SER TIME</TextOne>
            <TextTwoEdited>Tenho responsabilidade no desenvolvimento do outro.</TextTwoEdited>
            <TextThree>Pilares: Coletividade, Empatia Assertiva e Pertencimento.</TextThree>
          </Box>
          <Box>
            <TextOne>SER GENUÍNE</TextOne>
            <TextTwoEdited>As coisas precisam ser ditas!</TextTwoEdited>
            <TextThree>Pilares: Consciência, Vulnerabilidade e Comunicação.</TextThree>
          </Box>
        </BoxContainer>
      </RightContainer>
    </Container>
  </BgContainer>
);
