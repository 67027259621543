import React from 'react';
import {
  EcoContainer, EcoStripes, EcoBox, EcoTitle, EcoSubtitle,
  SparkleImageLeft, SparkleImageRight,
  FadingContainerTop, FadingContainerBottom, WordHolder,
} from './style';
import {
  Sparkles, Waves, AltSparkles, WavesLogosEco,
} from '../../assets';
import { FadingWords } from '../../components/Fading Words';
import { RoundPanel } from '../../components/RoundPanel';

export const Ecosystem: React.FC = () => (
  <EcoContainer>
    <SparkleImageLeft src={Sparkles} />
    <SparkleImageRight src={AltSparkles} />
    <EcoBox>
      <EcoTitle>ECOSSISTEMA ETHOS</EcoTitle>
      <EcoSubtitle>Quem confia no nosso trabalho</EcoSubtitle>
    </EcoBox>
    <WordHolder>
      <FadingContainerTop>
        <FadingWords word="HUMANIZAR" />
        <FadingWords word="EVOLUÇÃO" />
      </FadingContainerTop>
      <FadingContainerBottom>
        <FadingWords word="PESSOAS" />
        <FadingWords word="GESTÃO" topM="-20px" />
      </FadingContainerBottom>
    </WordHolder>
    {/* <EcoStripes src={Waves} /> */}
    {/* <RoundPanel /> */}
    <EcoStripes src={WavesLogosEco} />
  </EcoContainer>
);
