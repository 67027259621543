import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 400px;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
  padding: 10px;
  overflow: hidden;
  
  //descobrir pq o border radius nao está funcionando
  border: 2px solid;
  background: transparent;
  border-image: linear-gradient(180deg, #fff, rgba(255, 255, 255, 0.1)) 1;
  border-radius: 10px;

  @media (max-width: 1300px){
    width: 350px;
    height: 300px;

    gap: 5px;
  }
  
  @media (max-width: 1100px){
    width: 85%;
    height: 375px;
  }

  @media (max-width: 640px){
    width: 90%;
    height: 450px;
  }
`;

export const CardTag = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: left;
  color: #fff;

  margin-bottom: 4px;

  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.115em;
`;

export const CardTitle = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const CardTitleText = styled.h1`
  font-family: 'Comfortaa';
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.115em;
  color: #FFFFFF;
  padding-bottom: 10px;
`;

export const CardContent = styled.div`
  width: 100%;
  display: flex;
  `;

export const CardContentText = styled.p`
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.115em;
  `;
