import styled from 'styled-components';

export const DataContainer = styled.div`
  height: 900px;
  display: flex;
  flex-direction: column;
  position: relative;

  padding-top: 125px;
  margin-top: 875px;

  @media only screen and (max-width: 900px) {
    height: 1200px;
  }

  @media (max-width: 600px) {
    height: 900px;
  }
`;

export const DataRows = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 60%;
  gap: 100px;
  justify-content: space-around;
  margin-top: 160px;

  @media only screen and (max-width: 1250px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const DataTitle = styled.div`
  color: #272727;
  font-family: "Comfortaa", "Times New Roman", Times, serif;
  font-size: 28px;
  margin: auto;
  margin-top: 133px;
  margin-bottom: 0px;
  text-align: center;

  @media (max-width: 640px) {
    font-size: 20px; 
  }
`;

export const DataNumber = styled.h2`
  font-size: 108px;
  background: -webkit-linear-gradient(top left, #d6c002, #3b924b);
  font-family: "Comfortaa", "Times New Roman", Times, serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  margin-bottom: 20px;
  @media only screen and (max-width: 1250px) {
    align-self: center;
  }

  @media (max-width: 640px) {
    font-size: 77px
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.h3`
  color: #000000;
  font-family: "Comfortaa", "Times New Roman", Times, serif;
  font-size: 28px;
  margin: auto;
  text-align: center;
  font-weight: 400;
  width: 200px;
  top: 30px;
  line-height: 40px;

  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

export const SparkleImageRight = styled.img`
  height: 300px;
  width: auto;
  position: absolute;
  top: 525px;
  right: 200px;
  @media only screen and (max-width: 900px) {
    height: 200px;
    right: 20px;
    top: 450px;
  }

  @media (max-width: 640px) {
    height: 150px;
    right: 20px;
    top: 800px;
  }
`;

export const SparkleImageLeft = styled.img`
  height: 300px;
  width: auto;
  position: absolute;
  transform: scaleX(-1) rotate(-30deg);
  top: 225px;
  left: 200px;
  @media only screen and (max-width: 900px) {
    height: 200px;
    left: 10px;
    transform: scaleX(-1);
  }
`;
