import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import {
  PageOne,
  PageTwo,
} from '../pages';

export const PublicRoutes = (): JSX.Element => (
  <BrowserRouter>
    <Route component={PageOne} exact path="/" />
    <Route component={PageTwo} exact path="/quemSomos" />
  </BrowserRouter>
);
