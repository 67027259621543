import styled from 'styled-components';

export const BgContainer = styled.div`
    width: 100%;
    height: 1000px;

    margin-top: -310px;
    margin-bottom: 50px;

    @media (max-width: 900px) {
        margin-top: -250px;;
    }

    @media (max-width: 750px) {
        margin-top: -225px;;
    }
    z-index: 1;
`;

export const BgImage = styled.img`
    background-size: cover;
    position: absolute;
    z-index: 1;
    width: 100%;
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fafafa;
    @media (max-width: 775px) {
        justify-content: center;
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 775px) {
        display: none;
    }
`;

export const Circle = styled.img`
    width: auto;
    height: 950px;
    z-index: 2;
    margin-top: 20em;

    @media (max-width: 800px) {
        width: auto;
        height: 650px;
    }
`;

export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: auto;
    padding-right: 115px;
    margin-top: 10em;
    z-index: 2;
    @media (max-width: 900px) {
        padding-right: 5%;
    }
    @media (max-width: 775px) {
        align-items: center;
        padding-right: 0;
    }
`;

export const PhaseContainer = styled.div`
    margin-bottom: 15px;
`;

export const PhaseOne = styled.h1`
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.115em;
    color: #333333;

    @media screen and (max-width: 400px) {
        font-size: 16px;
        line-height: 20px;
    }
`;

export const PhaseTwo = styled.h1`
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.115em;
    color: #333333;

    @media screen and (max-width: 400px) {
        font-size: 24px;
        line-height: 32px;
    }
`;

export const BoxContainer = styled.div`
    width: 500px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap-reverse;
    justify-content: center;
    height: 750px;
    gap: 24px;

    @media (max-width: 1250px) {
        width: auto;
        height: auto;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 15px;
    }
`;

export const TextOne = styled.h1`
    width: 100%;
    height: auto;

    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 11.5%;
    color: #464646;
    -webkit-text-stroke: 1px currentColor;

    @media (max-width: 1250px) {
        font-size: 18px;
        line-height: 19px;
    }

    @media (max-width: 400px) {
        font-size: 16px;
        line-height: 17px;
    }
`;

export const Box = styled.div`
    width: 300px;
    height: 182px;

    padding: 25px 10px 0 20px;

    background: #FFFFFF;
    box-shadow: 0px 0.791304px 4.74783px rgba(0, 0, 0, 0.25);
    background: linear-gradient(90deg,#63A84F,#BCC358) left bottom #FFFFFF no-repeat;
    background-size:100% 13px ;
    border-radius:5px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 25px;

    @media (max-width: 1250px) {
        width: 250px;
        height: 164px;
    }

    @media (max-width: 400px) {
        width: 225px;
        height: 164px;
    }

    &:hover ${TextOne} {
        background-clip: text;
        background: -webkit-linear-gradient(0deg, #BCC358, #63A84F);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px transparent;
        transition: 0.5s;
    }
`;

export const TextTwo = styled.h1`
    width: 100%;
    height: auto;

    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.115em;
    color: #464646;

    @media (max-width: 1250px) {
        font-size: 10px;
        line-height: 11px;
    }
`;

export const TextTwoEdited = styled.h1`
    width: 100%;
    height: auto;

    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.115em;
    color: #464646;

    @media (max-width: 1250px) {
        font-size: 10px;
        line-height: 11px;
    }
`;

export const TextThree = styled.h1`
    width: 100%;
    height: auto;

    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0.115em;
    color: #464646;

    @media (max-width: 1250px) {
        font-size: 10px;
        line-height: 11px;
    }
`;
