import styled from 'styled-components';

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 0;
  z-index: 100;
  padding: 0 0 0 30px;
  height: 120px;

  @media (max-width: 640px) {
    padding: 0 10px 0 10px;
  }
`;

export const Logo = styled.img`
  width: 200px;
  height: 100px;
  object-fit: contain;

  @media (max-width: 640px) {
    width: 125px;
    height: 62.5px;
  }
`;

export const EmptyDiv = styled.div`
  width: 200px;

  @media (max-width: 640px) {
    display: none;
  }
`;

export const HumburguerMenu = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 4px;
  width: 50px;
  height: 20px;
  background: transparent;
  border: none;
  z-index: 1;

  &:hover{
    cursor: pointer;
  }

  &:focus-visible{
    outline: none;
  }

  &.active > span:nth-child(1) {
    transform: translateY(8px) rotate(-45deg);
  }

  &.active > span:nth-child(2) {
    transform: translateX(5.5px) translateY(0px) rotate(45deg);
    opacity: 0;
  }

  &.active > span:nth-child(3) {
    transform: translateY(-8px) rotate(45deg);
  }

  @media (max-width: 640px) {
    width: 33px;
    align-items: flex-end;
  } 
`;

export const HumburguerLine1 = styled.span`
  width: 33px;
  height: 3px;
  background-color: #000;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
`;

export const HumburguerLine2 = styled.span`
  width: 21px;
  height: 3px;
  background-color: #000;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
`;

export const HumburguerLine3 = styled.span`
  width: 33px;
  height: 3px;
  background-color: #000;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
`;

export const InnerMenuContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  gap: 75px;
  padding-right: 30px;

  @media (max-width: 400px) {
    padding-right: 0;
  }
`;

export const MenuContainer = styled.div`
  pointer-events: none;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  box-shadow: 0px 24px 50px 0 rgba(0, 0, 0, 0.17);
  background-color: #fafafa;
  opacity: 0;
  top: 60px;
  position: absolute;
  transition: all 0.7s ease-in-out;
  left: 0;

  @media (max-width: 400px) {
    padding: 0 15px 0 15px;
  }

  &.active {
    opacity: 1;
    transform: translateY(40px);
  }


`;

export const MenuItem = styled.div`
  pointer-events: none;
  transition: all 0.7s ease-in-out;
  transform: translateY(-70px);

  &.active {
    transform: translateY(0);
    pointer-events: all;
  }
`;

export const Links = styled.a`
  font-family: comfortaa;
  color: #000;
  font-weight: 000;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 400px) {
    font-size: 12px;
  }

  &:hover{
    color: #63A84F;
  }

  a{
    color:#000;
    text-decoration: none;

    &:hover{
      -webkit-text-fill-color: #63A84F
    }

    &.active{
      -webkit-text-stroke: 0.5px #63A84F;
      -webkit-text-fill-color: #63A84F;
    }
  }
`;
