import styled from 'styled-components';

export const SectionSub = styled.section`
    display: flex;
    justify-content: center;
    padding-top: 2em;
    flex-wrap: wrap;
    margin-bottom: -20em;
    @media (min-width: 1450px){
        margin-bottom: -20em;
    }
    @media (min-width: 640px) and (max-width: 990px){
        margin-bottom: -40em;
    }
    @media (max-width: 640px){
        margin-bottom: -40em;
    }
    @media (max-width: 400px){
        margin-bottom: -20em;
    }
`;

export const LeftSection = styled.div`
    padding-top: 2em;
    width: 350px;
    height: 250px;
    margin-left: 5px;
`;

export const Title = styled.h1`
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.135em;
    color: #000000;

    @media (max-width: 900px){
        font-size: 22px;
    }
`;

export const BtnForms = styled.button`
    padding: 20px 60px;
    border: none;
    outline: none;
    position: relative;
    border-radius: 3px;
    background: linear-gradient(to right, #84B153, #419F4B);
    cursor: pointer;
    width: 317px;
    height: 62px;
    font-family: 'Comfortaa', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.29em;
    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15);
    margin-top: 30px;
    z-index: 1;
    &:before {
        content: "";
        position: absolute;
        left: 3px;
        right: 3px;
        top: 3px;
        bottom: 3px;
        border-radius: 4px;
        background-color: #fafafa;
        z-index: -1;
    }
    &:after{
        content: "INSCREVA-SE";
        font-size: 16px;
        background: linear-gradient(to top, #84B153, #419F4B);
        -webkit-background-clip: text;
        color: transparent;
        transition: 200ms
    }
    &:hover::before {
        opacity: 0%;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }
    &:hover::after{
        color: #fafafa;
    }
`;

export const CenterSection = styled.div`
    width: 700px;
    height: 700px;
    margin-bottom: -4em;
    z-index: -1;
    @media (max-width: 1450px){
        width: 400px;
        height: 400px;
        order: 3;
        margin-bottom: -40px;
    }
    /* @media (min-width: 200px) and (max-width: 640px) { 
        order: 2;
        padding-top: 100px;
        margin-bottom: -30em;
    } */
`;

export const ImageSub = styled.img`
    height: 700px;

    @media (max-width: 1450px){
        width: 400px;
        height: 400px;
    }
`;

export const RightSection = styled.div`
    padding-top: 2em;
    width: 380px;
    @media (min-width: 200px) and (max-width: 640px) {
        width: 80%;
    }
`;

export const Description = styled.p`
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: 0.135em;
    color: #737373;

    @media (max-width: 900px){
        font-size: 18px;
    }
`;

export const EnterLink = styled.a``;
