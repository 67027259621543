import styled from 'styled-components';
import { BackgroundMultiverse } from '../../assets';

export const SparklesMultiverse = styled.img`
  width: 40%;
  height: 60%;
  position: relative;
  top: 15%;
  left: 50%;
`;

export const MultiContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundMultiverse});
  background-size: cover;
  display: flex;
  padding-bottom: 15%;
  font-family: "Comfortaa", "Times New Roman", Times, serif;
  @media only screen and (max-width: 1250px){
    margin-top: 10%;
  }
  @media only screen and (max-width: 1050px){
    margin-top: 15%;
  }
  @media only screen and (max-width: 900px){
    margin-top: 20%;
  }
  @media only screen and (max-width: 750px){
    margin-top: 25%;
  }
  @media (min-width: 200px) and (max-width: 640px) {
        margin-top: 50%;
        margin-bottom: 50px;
    }
  background-color: #fafafa;
  `;

export const Galaxy = styled.img`
  width: 60%;
  height: 80%;
  margin-top: 25%;
  @media (min-width: 200px) and (max-width: 640px) {
        margin-top: 50%
    }
`;

export const LogoContainer = styled.div`
  display: flex;
  gap: 5%;
`;

export const LogoImages = styled.img`
  @media (min-width: 200px) and (max-width: 640px) {
        width: 50px;
    }
`;

export const DataContainer = styled.div`
  margin-top: 20%;
  width: 33%;
  @media only screen and (max-width: 1300px){
    margin-top: 10%;
  }
  @media (min-width: 200px) and (max-width: 640px) {
        margin-top: 35%;
    }
`;

export const SectionTitle = styled.h1`
  margin-top: 15%;
  font-size: 1.5rem;
  color: #fff;
  @media only screen and (max-width: 1300px){
    margin-top: 10%;
  }
  @media (min-width: 200px) and (max-width: 640px) {
        font-size: 1rem;
    }
`;

export const VoicesImage = styled.img`
  width: 100%;
  margin-top: 5%;
  margin-left: -5%;
`;

export const SectionText = styled.p`
  margin-top: 17%;
  font-size: 1.08rem;
  text-align: justify;
  line-height: 1.7rem;
  @media only screen and (max-width: 1100px){
    font-size: 1rem;
    margin-top: 10%;
  }
  @media only screen and (max-width: 900px){
    font-size: 0.8rem;
    margin-top: 8%;
    line-height: 1.2rem;
  }
  @media only screen and (max-width: 760px){
    font-size: 0.7rem;
    margin-top: 8%;
    line-height: 1rem;
  }
`;

export const EnterButton = styled.button`
  width: 100%;
  height: 7%;
  margin-top: 8%;
  border-radius: 0.5rem;
  color: #fff;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #8B31CD, #4D0483);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #3D0268 inset;
  &:hover {
    transition: 2s;
    box-shadow: none;
    cursor: pointer;
  }
  @media only screen and (max-width: 750px){
    font-size: 0.8rem;
  }
`;

export const EnterLink = styled.a`
  color: #fff;
`;
