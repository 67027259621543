import styled from 'styled-components';

export const BackGroundColor = styled.div`
    height: 700px;
    overflow: hidden;
    z-index:2;
    @media (min-width: 200px) and (max-width: 640px) {
        height: 800px;
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 900px) {
        flex-direction: row;
    }
    @media (min-width: 200px) and (max-width: 640px)  {
        flex-direction: column;
        padding-top: 3em; 
    }
`;

export const BoxImg = styled.div`
    height: 80vh;
`;

export const Home2Image = styled.img`
    position: relative;
    height: 60vh;
    top: 8rem;
    z-index: 1;
    @media (max-width: 900px) {
        width: 500px;
        height: 500px;
    }
    @media (min-width: 200px) and (max-width: 640px) {
        top: 5vh;
        width: 99%;
        /* right: -10em; */
        height: 400px;
        /* display: none; */
    }
`;
export const Home2Vector = styled.img`
    position: absolute;
    z-index: 0;
    right: 0;
    width: 900px;
    height: 600px;
    background: radial-gradient(150% 55% at 170% 50%, rgba(65, 159, 75, 0.7) 0%, #419F4B 0.01%, rgba(132, 177, 83, 0) 100%);
    @media (max-width: 900px) {
        height: 150vh;
        width: 60vw;
        background: none;
    }
    @media (min-width: 200px) and (max-width: 640px) {
        display: none;
    }
`;

export const BoxTitles = styled.div`
    display: flex;
    flex-direction: column;
    //margin: 12rem 0 0 4rem;
    gap: 30px;
    width: 500px;
    @media (min-width: 200px) and (max-width: 640px) {
        width: 200px;
    }
`;

export const Title = styled.text`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: 0.115em;
    color: #37944A;

    @media (max-width: 900px) {
        font-size: 18px;
    }
`;

export const SubTitle = styled.text`
    font-style: normal;
    font-weight: 500;
    font-size: 40.3969px;
    line-height: 99%;
    letter-spacing: 0.08em;
    color: #272727;

    @media (max-width: 900px) {
        font-size: 24px;
        margin-top: 1em;
    }
`;
