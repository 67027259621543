import React from 'react';
import {
  CarouselContainer, SectionTitle, SectionSecondaryTitle, SectionText,
  InnerContainer, CarouselContainerStyled, TextContainer, ContactButton,
} from './style';
import { CardServices } from './CardServices';

export const Services = () => {
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2.9,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          dots: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '20px',
          dots: false,
          centerMode: true,
        },
      },
    ],
  };

  const content = [
    {
      title: 'Consultoria em processo seletivo',
      text: 'A Consultoria em Psel é um serviço que atua na orientação e capacitação da equipe interna para o recrutamento e seleção de novos membros, com o intuito de realizar um processo seletivo assertivo que atenda às necessidades e objetivos da empresa',
      tag: 'ATRAÇÃO E CAPTAÇÃO DE TALENTOS',
    },
    {
      title: 'Recrutamento e seleção',
      text: 'Recrutamento e seleção de novos colaboradores, com o intuito de atrair e filtrar pessoas qualificadas para determinado cargo na organização.',
      tag: 'ATRAÇÃO E CAPTAÇÃO DE TALENTOS',
    },
    {
      title: 'Recrutamento e seleção Tech',
      text: 'Recrutamento e seleção de novos colaboradores, com o intuito de atrair e filtrar pessoas qualificadas para determinado cargo na organização na área da tecnologia',
      tag: 'ATRAÇÃO E CAPTAÇÃO DE TALENTOS',
    },
    {
      title: 'Consultoria em RH estratégico',
      text: 'Entendimento do contexto do RH da empresa e criação de estratégias e planos para a área de pessoas a partir do uso de people analytics a fim de proporcionar uma melhor gestão de pessoas e processos dentro da organização',
      tag: 'RH ESTRATÉGICO',
    },
    {
      title: 'Jornada de formação',
      text: 'O serviço atua na capacitação de indivíduos e equipes para o desenvolvimento de habilidades essenciais atendendo às necessidades e dores específicas do negócio',
      tag: 'FORMAÇÃO DE LIDERANÇAS CONSCIENTES',
    },
    {
      title: 'Consultoria em cultura organizacional',
      text: 'Consultoria que busca identificar os níveis de compreensão da cultura organizacional da empresa, bem como possíveis pontos de dificuldades, bem como, formas de transmitir a cultura e de promover a vivência da empresa através de ritos e artefatos realizados de forma recorrente',
      tag: 'CULTURA ORGANIZACIONAL',
    },
    {
      title: 'Consultoria em diversidade e inclusão',
      text: 'O serviço tem como objetivo ajudar outras empresas no entendimento e estruturação do processo de pluralidade na organização',
      tag: 'CULTURA ORGANIZACIONAL',
    },
    {
      title: 'Plano de desenvolvimento individual',
      text: 'Ferramenta de aperfeiçoamento profissional formada por passos que colaboram no roteiro para atingimento de um objetivo pessoal/profissional',
      tag: 'CULTURA ORGANIZACIONAL',
    },
    {
      title: 'Plano de carreira',
      text: 'Consiste na definição de soft e hard skills necessárias para cada cargo na empresa, levando em consideração o escopo dos papéis',
      tag: 'CULTURA ORGANIZACIONAL',
    },
  ];

  return (
    <CarouselContainer>
      <TextContainer height="80">
        <SectionTitle>
          NOSSOS SERVIÇOS
        </SectionTitle>
      </TextContainer>
      <TextContainer height="80" margin="0 0 36px 0">
        <SectionSecondaryTitle>
          O que fazemos
        </SectionSecondaryTitle>
      </TextContainer>
      <InnerContainer>
        <CarouselContainerStyled {...settings}>
          {content.map((item) => (
            <CardServices title={item.title} text={item.text} tag={item.tag} />
          ))}
        </CarouselContainerStyled>
      </InnerContainer>

      <TextContainer height="80" margin="42px 0">
        <SectionText>
          Está interessado em mudar a forma que sua empresa
          funciona através da gestão?  O que fazemos
        </SectionText>
      </TextContainer>
      <TextContainer height="80" margin="0">
        <a href="#contact">
          <ContactButton>
            ENTRE EM CONTATO
          </ContactButton>
        </a>
      </TextContainer>
    </CarouselContainer>

  );
};
