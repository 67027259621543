import React, { useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  BoxTwo, Box, BoxThree, ButtonStyled,
  ButtonBack, ButtonNext, ButtonClose,
  Circle, Container, DetailsContainer,
  ExpandContainer, FirstPhrase,
  SecondPhrase, ThirdPhrase,
  TextBox, TextOne, TextTwo,
} from './style';

const conteudo = [
  {
    position1: 'Diretoria de Soluções',
    text1: 'Acompanhar a área de projetos, juntamente com o relacionamento com o cliente. Também visa inovar e criar novos serviços.',

    position2: 'Gerente e Consultor de soluções',
    text2: ' Proporcionar a execução de projetos e soluções coerentes com a realidade dos envolvidos, garantindo a qualidade nos projetos da Ethos.',

    position3: 'Diretoria de Gente e Gestão',
    text3: 'Responsável por traçar planos de ação e monitorar o desempenho dos indicadores da área de Gente da Ethos. Além de direcionar a Estratégia Financeira da Ethos, através da gestão dos recursos e planejamento das finanças da empresa.',

    position4: 'Gerente e Consultor de Gente e Gestão',
    text4: 'Executa os Processos Seletivos referentes à empresa, monitora e analisa as avaliações internas, prezando pelo clima e cultura organizacional, além de cuidar de programas e repasses voltados à Gente e Gestão na Ethos.',

    position5: ' Diretoria de Negócios',
    text5: 'Acompanhar a área de negócios, especialmente no que tange a venda e negociação com clientes. Gere também o relacionamento com o cliente e é suporte aos cargos de gerência e Consultor de negócios.',

    position6: 'Gerente de Negócios',
    text6: 'Proporcionar a venda de soluções coerentes com a realidade dos envolvidos, por meio de identificação de novas oportunidades. De acordo com o conhecimento total do Core Business da Ethos e alinhamento com clientes.',

    position7: 'Gerente de Marketing',
    text7: 'Pensar e executar as estratégias de Marketing da Ethos, visando um maior posicionamento e fortalecimento de marca no mercado. Com isso, entender o funcionamento interno e externo da marca, gerando mais oportunidades de negócio através dos processos e atividades da área.',

    position8: 'Presidência Institucional',
    text8: ' Acompanhar a área de Mercado, exercendo frentes de Institucional, Comercial e Marketing. Com isso, o cargo engloba o posicionamento com parceiros e stakeholders, monitoramento de negociações e de estratégias comerciais.',
  },
];

export const Process = () => {
  const [text1, setText1] = useState(true);
  const [text2, setText2] = useState(true);
  const [text3, setText3] = useState(true);
  const [text4, setText4] = useState(true);
  const [next, setNext] = useState(true);
  return (
    <Container>
      <FirstPhrase>FAÇA PARTE DA ETHOS</FirstPhrase>
      <SecondPhrase>Nosso processo seletivo</SecondPhrase>
      <ThirdPhrase>
        A Ethos Consultoria Júnior, vinculada à Universidade Federal de Pernambuco, tem como
        missão desenvolver pessoas e humanizar ambientes, focando em proporcionar uma
        vivência empresarial aos estudantes por meio do alicerce da Psicologia. A organização é
        sem fins lucrativos e busca estudantes de todos os cursos da Universidade que desejem
        empreender, se desenvolver e experienciar novos desafios.
        Nesse processo seletivo, qualquer pessoa matriculada em um curso de graduação da UFPE
        pode se inscrever.
        <br />
        Vem fazer parte do nosso time! Inscrições abertas!
      </ThirdPhrase>
      {conteudo.map((item) => (
        <>
          <Box>
            <BoxThree>
              <BoxTwo>
                <Circle> </Circle>
                <TextBox>
                  {next
                    ? (
                      <ExpandContainer>
                        <TextOne>{item.position1}</TextOne>
                        {text1
                          ? (
                            <ButtonStyled type="default" onClick={() => setText1(!text1)}>+ Ver detalhes</ButtonStyled>
                          ) : (
                            <DetailsContainer>
                              <TextTwo>{item.text1}</TextTwo>
                              <ButtonClose type="default" shape="circle" onClick={() => setText1(!text1)}>X</ButtonClose>
                            </DetailsContainer>
                          )}
                      </ExpandContainer>
                    )
                    : (
                      <ExpandContainer>
                        <TextOne>{item.position5}</TextOne>
                        {text1
                          ? (
                            <ButtonStyled type="default" onClick={() => setText1(!text1)}>+ Ver detalhes</ButtonStyled>
                          ) : (
                            <DetailsContainer>
                              <TextTwo>{item.text5}</TextTwo>
                              <ButtonClose type="default" shape="circle" onClick={() => setText1(!text1)}>X</ButtonClose>
                            </DetailsContainer>
                          )}
                      </ExpandContainer>
                    )}
                </TextBox>
              </BoxTwo>
              <BoxTwo>
                <Circle> </Circle>
                <TextBox>
                  {next
                    ? (
                      <ExpandContainer>
                        <TextOne>{item.position3}</TextOne>
                        {text2
                          ? (
                            <ButtonStyled type="default" onClick={() => setText2(!text2)}>+ Ver detalhes</ButtonStyled>
                          ) : (
                            <DetailsContainer>
                              <TextTwo>{item.text3}</TextTwo>
                              <ButtonClose type="default" shape="circle" onClick={() => setText2(!text2)}>X</ButtonClose>
                            </DetailsContainer>
                          )}
                      </ExpandContainer>
                    )
                    : (
                      <ExpandContainer>
                        <TextOne>{item.position6}</TextOne>
                        {text2
                          ? (
                            <ButtonStyled type="default" onClick={() => setText2(!text2)}>+ Ver detalhes</ButtonStyled>
                          ) : (
                            <DetailsContainer>
                              <TextTwo>{item.text6}</TextTwo>
                              <ButtonClose type="default" shape="circle" onClick={() => setText2(!text2)}>X</ButtonClose>
                            </DetailsContainer>
                          )}
                      </ExpandContainer>
                    )}
                </TextBox>
              </BoxTwo>
            </BoxThree>
            <BoxThree>
              <BoxTwo>
                <Circle> </Circle>
                <TextBox>
                  {next
                    ? (
                      <ExpandContainer>
                        <TextOne>{item.position2}</TextOne>
                        {text3
                          ? (
                            <ButtonStyled type="default" onClick={() => setText3(!text3)}>+ Ver detalhes</ButtonStyled>
                          ) : (
                            <DetailsContainer>
                              <TextTwo>{item.text2}</TextTwo>
                              <ButtonClose type="default" shape="circle" onClick={() => setText3(!text3)}>X</ButtonClose>
                            </DetailsContainer>
                          )}
                      </ExpandContainer>
                    )
                    : (
                      <ExpandContainer>
                        <TextOne>{item.position7}</TextOne>
                        {text3
                          ? (
                            <ButtonStyled type="default" onClick={() => setText3(!text3)}>+ Ver detalhes</ButtonStyled>
                          ) : (
                            <DetailsContainer>
                              <TextTwo>{item.text7}</TextTwo>
                              <ButtonClose type="default" shape="circle" onClick={() => setText3(!text3)}>X</ButtonClose>
                            </DetailsContainer>
                          )}
                      </ExpandContainer>
                    )}
                </TextBox>
              </BoxTwo>
              <BoxTwo>
                <Circle> </Circle>
                <TextBox>
                  {next
                    ? (
                      <ExpandContainer>
                        <TextOne>{item.position4}</TextOne>
                        {text4
                          ? (
                            <ButtonStyled type="default" onClick={() => setText4(!text4)}>+ Ver detalhes</ButtonStyled>
                          ) : (
                            <DetailsContainer>
                              <TextTwo>{item.text4}</TextTwo>
                              <ButtonClose type="default" shape="circle" onClick={() => setText4(!text4)}>X</ButtonClose>
                            </DetailsContainer>
                          )}
                      </ExpandContainer>
                    )
                    : (
                      <ExpandContainer>
                        <TextOne>{item.position8}</TextOne>
                        {text4
                          ? (
                            <ButtonStyled type="default" onClick={() => setText4(!text4)}>+ Ver detalhes</ButtonStyled>
                          ) : (
                            <DetailsContainer>
                              <TextTwo>{item.text8}</TextTwo>
                              <ButtonClose type="default" shape="circle" onClick={() => setText4(!text4)}>X</ButtonClose>
                            </DetailsContainer>
                          )}
                      </ExpandContainer>
                    )}
                </TextBox>
              </BoxTwo>
            </BoxThree>
          </Box>
        </>
      ))}
      <ButtonBack type="link" shape="circle" icon={<LeftOutlined />} onClick={() => setNext(!next)}> </ButtonBack>
      <ButtonNext type="link" shape="circle" icon={<RightOutlined />} onClick={() => setNext(!next)}> </ButtonNext>
    </Container>
  );
};
