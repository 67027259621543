import React from 'react';
import {
  CarouselWrapper,
  Title,
  Title2,
  TextContainer,
  CarouselContainer,
  ContainerCases,
} from './styles';
import { CardCases } from './CardCases';
import {
  Amorito,
  Anpsinep,
  Deca,
  Enviou,
  HeadWay,
} from '../../assets';

export const Carrossel = () => {
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const content = [
    {
      name: 'Anpsinep',
      text: 'Recrutamento e seleção com recorte racial feito para a Articulação de Psicólogos(as) Negros(as) e Pesquisadores (as) que contou mais de 790 inscrições',
      service: 'Serviços',
      boxText: ' ATRAÇÃO E CAPTAÇÃO DE TALENTOS',
      image: Anpsinep,
    },
    {
      name: 'Enviou',
      text: 'Primeiro recrutamento e seleção realizado para a área de tecnologia.',
      service: 'Serviços',
      boxText: ' ATRAÇÃO E CAPTAÇÃO DE TALENTOS',
      image: Enviou,
    },
    {
      name: 'Deca',
      text: 'Projeto realizado por 3 Empresas Juniores, sendo elas, Ethos Consultoria Jr, Arco Consultoria e Ciclo Consultoria. O serviço foi capaz de impactar 3 objetivos de desenvolvimento sustentável da ONU: saúde e bem estar; trabalho decente e crescimento econômico; indústria, inovação e infraestrutura.',
      service: 'Serviços',
      boxText: ' RH ESTRATÉGICO',
      image: Deca,
    },
    {
      name: 'Headway',
      text: 'Esse projeto foi realizado em conjunto com uma empresa júnior de nutrição, juntas puderam compartilhar experiências e conexões criando o “Produtividade Remota” (PR), que foi aplicado em uma escola de idiomas.',
      service: 'Serviços',
      boxText: ' RH ESTRATÉGICO',
      image: HeadWay,
    },
    {
      name: 'Amorito',
      text: 'Recrutamento e seleção que proporcionou processos de captação de perfis assertivos para a vaga.',
      service: 'Serviços',
      boxText: ' ATRAÇÃO E CAPTAÇÃO DE TALENTOS',
      image: Amorito,
    },
  ];

  return (
    <ContainerCases>
      <TextContainer>
        <Title>NOSSOS CASES</Title>
      </TextContainer>
      <TextContainer>
        <Title2>Experiências com a Ethos</Title2>
      </TextContainer>
      <CarouselContainer>
        <CarouselWrapper {...settings}>
          {content.map((item) => (
            <CardCases
              name={item.name}
              text={item.text}
              service={item.service}
              boxText={item.boxText}
              image={item.image}
            />
          ))}
        </CarouselWrapper>
      </CarouselContainer>
    </ContainerCases>
  );
};
