import React, { useState } from 'react';
import dotenv from 'dotenv';
import axios from 'axios';
import { Home2Photo } from '../../assets';
import {
  CallContainer, CallForm, CallFormsContainer, CallImage, CallInput,
  CallText, CallTitle, FieldTitle, TopBox, CallSlide, SlideText,
  CallButton, CallInputArea, LoadingRing, MessageAlert, ContainerAlert,
} from './style';

dotenv.config();

export const CallToAction = () => {
  const [value, setValue] = useState('0');

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.currentTarget.value);
  };

  const evaluateText = (val: string) => {
    if (parseInt(val, 10) <= 66) {
      return 'Até 5mil';
    }
    if (parseInt(val, 10) <= 132) {
      return '7 mil - 10 mil';
    }
    if (parseInt(val, 10) <= 198) {
      return '12 mil - 15 mil';
    }
    if (parseInt(val, 10) <= 264) {
      return '17 mil - 20 mil';
    }
    return '20 mil+';
  };

  const [isLoading, setIsLoading] = useState(false);

  const [view, setView] = useState(false);

  const viewMessage = () => {
    setView(true);
    setTimeout(() => {
      setView(false);
    }, 2000);
  };

  const loading = () => {
    setIsLoading(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    data.value = evaluateText(value);
    setIsLoading(true);
    axios.post('https://ethos-back-oimn.onrender.com/email', data)
      .finally(() => {
        setIsLoading(false);
        viewMessage();
      });
  };

  return (
    <CallContainer id="contact">
      <CallFormsContainer onSubmit={handleSubmit}>
        <TopBox>
          <CallTitle>HUMANIZE CONOSCO</CallTitle>
          <CallText>Contate a Ethos</CallText>
        </TopBox>
        <CallForm>
          <FieldTitle>Qual é o seu nome?</FieldTitle>
          <CallInput type="text" name="name" height="40px" />
        </CallForm>
        <CallForm>
          <FieldTitle>Qual seu e-mail?</FieldTitle>
          <CallInput type="text" name="email" height="40px" />
        </CallForm>
        <CallForm>
          <FieldTitle>Como podemos te ajudar?</FieldTitle>
          <CallInputArea name="message" height="100px" />
        </CallForm>
        <CallForm>
          <FieldTitle>Quanto pretende investir?</FieldTitle>
          <CallSlide type="range" min={0} max={320} value={value} className="slider" name="value" onChange={handleChangeInput} />
          <SlideText value={value} onChange={handleChangeInput}>
            {evaluateText(value)}
          </SlideText>
        </CallForm>
        <CallButton type="submit" className={isLoading ? 'active' : ''} onClick={loading}>
          {isLoading ? <LoadingRing /> : ''}
        </CallButton>
        <ContainerAlert>
          <MessageAlert className={view ? 'active' : ''}>
            Enviado com sucesso!
          </MessageAlert>
        </ContainerAlert>
      </CallFormsContainer>
      <CallImage src={Home2Photo} />
    </CallContainer>
  );
};
