import { Carousel } from 'antd';
import styled from 'styled-components';
import { BackgroundSvg } from '../../assets';

export const CarouselContainer = styled.div`
  width: 100%;
  height: 1500px;
  display: flex;
  margin: -500px 0 0 0;
  justify-content: center;
  flex-direction: column;
  background-image: url(${BackgroundSvg});
  background-size: cover;
  position: absolute;
  z-index: 5;
  overflow: hidden;
`;

export const CarouselContainerStyled = styled(Carousel)`
  > .slick-dots li button {
    width: 35px;
    height: 4px;
    border-radius: 5px;
    background: #808080;

    transition: 0.5s;
  }

  & .slick-active {
      width: 24px;
      margin-left: -24px !important;
  }

  > .slick-dots li.slick-active button {
    width: 55px;
    height: 4px;
    border-radius: 5px;
    background: #ffffff;

    margin-left: 15px;
    margin-right: 15px;

    transition: 0.5s;
  }

  > .slick-dots {
    display: flex !important;
    flex-direction: row;
    gap: 25px;
    margin-left: -10%;
  }

  > .slick-dots li{
    padding: 0px 10px 0px 10px;
    margin:0;
    list-style: none;
    border: none;
    text-decoration: none;
  }

  > .slick-dots li.slick-active{
    padding: 0px 30px 0px 10px;
    margin:0;
  }

  > .slick-dots-bottom{
    top: 120%;
  }

  > .slick-dots li button { 
    font-size: 0; 
    cursor: pointer;
    border: none;
  } 

  @media (max-width: 1000px) {
    > .slick-dots-bottom {
      top: 150%;
    }
  }
  @media (max-width: 1300px) {
    > .slick-list {
      padding: 0 1.5% !important;
    }
  }
  @media (max-width: 640px) {
    > .slick-list {
      padding: 0 7% !important;
    }

    > .slick-dots li button {
      width: 15px;
      height: 4px;
      border-radius: 5px;
      background: #808080;

      transition: 0.5s;
    }

    & .slick-active {
        width: 0;
        margin-left: 0 !important;
    }

    > .slick-dots li.slick-active button {
      width: 15px;
      height: 4px;
      border-radius: 5px;
      background: #ffffff;

      margin-left: 0;
      margin-right: 0;

      transition: 0.5s;
    }

    > .slick-dots {
      display: flex !important;
      flex-direction: row;
      gap: 15px;
      margin-left: 0;
    }

    > .slick-dots li{
      padding: 0px 0px 0px 0px;
      margin:0;
      list-style: none;
      border: none;
      text-decoration: none;
    }

    > .slick-dots li.slick-active{
      padding: 0px 0px 0px 0px;
      margin:0;
    }

    > .slick-dots-bottom{
      top: 115%;
    }

    > .slick-dots li button { 
      font-size: 0; 
      cursor: pointer;
      border: none;
    } 

    left: 1%;
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  padding: 0 20px 0 100px;
  /* padding: 16px 0 0 0; */
  /* margin: 0 5% 0 20px; */
  /* justify-content: space-between; */

  & .ant-carousel {
    width: 100%;
  }

  & .slick-track {
    display: flex;
  }

  & .ant-carousel{
    overflow: hidden;
  }

  & .slick-slide {
    margin-left: 0px;
  }

  @media (max-width: 1250px) {
    width: 97%;
    height: 525px;
    margin: 0 1.5% 0 1.5%;
  }

  @media (max-width: 640px) {
    /* width: 220%; */
    padding: 0 2% 0 2%;
  }
`;

interface TextContainerProps {
  height?: string;
  margin?: string;
}

export const TextContainer = styled.div.attrs((props: TextContainerProps) => ({
  height: props.height || '80',
  margin: props.margin || '0',
}))`

  
  width: 100%;
  height: ${(props) => props.height}px;
  margin: ${(props) => props.margin};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SectionTitle = styled.h1`
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;

  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

export const SectionSecondaryTitle = styled.h1`
  font-family: 'Comfortaa';
  color: #ffffff;
  font-size: 42px;
  font-weight: 700;

  @media (max-width: 640px) {
    font-size: 32px;
  }
`;

export const SectionText = styled.p`
  color: #ffffff;
  font-size: 28px;
  max-width: 645px;
  word-wrap: normal;
  font-weight: 400;
  text-align: center;

  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

export const ContactButton = styled.button`
  width: 350px;
  height: 50px;
  background: #ffffff;
  border-radius: 4px;
  border: none;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.29em;

  color: #141414;

  text-shadow: 0px 1.59698px 0.798489px rgba(0, 0, 0, 0.15);

  z-index: 1;

  &:hover {
    cursor: pointer;
    background-color: #909193;

    transition: 0.5s;
  }

  @media (max-width: 640px) {
    width: 250px;
    font-size: 12px;
  }
`;
